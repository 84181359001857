import { PartyTable } from "utils/Constants";
import ServiceBase from "services/ServiceBase";
import firebase from "utils/Firebase";
import PartyDto from "dto/PartyDto";
import PartyModel from "model/PartyModel";

// export class Party {
//   private _id: string;
//   public get Id(): string {
//     return this._id;
//   }
//   public set Id(v: string) {
//     this._id = v;
//   }

//   private name: string;
//   public get Name(): string {
//     return this.name;
//   }
//   public set Name(v: string) {
//     this.name = v;
//   }

//   private dueAmount: number;
//   public get DueAmount(): number {
//     return this.dueAmount;
//   }
//   public set DueAmount(v: number) {
//     this.dueAmount = v;
//   }

//   private isCust: number;
//   public get IsCust(): number {
//     return this.isCust;
//   }
//   public set IsCust(v: number) {
//     this.isCust = v;
//   }

//   private isVendor: number;
//   public get IsVendor(): number {
//     return this.isVendor;
//   }
//   public set IsVendor(v: number) {
//     this.isVendor = v;
//   }
// }

export function partyDtoToModel(dto: PartyDto) {
  let p: PartyModel = new PartyModel();
  if (dto == null) return null;
  p.Id = dto.id
  p.DueAmount = dto.dueAmount;
  p.IsCust = dto.isCust;
  p.IsVendor = dto.isVendor;
  p.Name = dto.name;

  return p;
}

export let PartyList: PartyModel[] = [];

export function SavePartyRepo(party: PartyModel, error?) {
  //    const des = deserialize<Item>(newItem, Item);

  let p: PartyDto = new PartyDto();
  p.dueAmount = party.DueAmount
  p.isCust = party.IsCust;
  p.isVendor = party.IsVendor
  p.name = party.Name 
  // item.Name = newItem.: n
  // item.salePrice = newItem.sp
  // item.purchasePrice = newItem.pp

  PartyList.push(party);

  let partyRef = firebase.database().ref(PartyTable());
  let key = partyRef.push().key;
  partyRef.child(key).set(p, err => {
    if(error) error(err)
  })

  return key;
}

export class AllPartiesLoader extends ServiceBase {
  action() {
    var items = firebase.database().ref(PartyTable());
    items.once("value", snapshot => {
      PartyList = []; // clear .. new Array
      console.log("Items = ", snapshot.val());
      snapshot.forEach(childSnapshot => {
        // key will be "ada" the first time and "alan" the second time
        let key = childSnapshot.key;
        // childData will be the actual contents of the child
        let childData: PartyDto = childSnapshot.val();
        childData.id = key;
        PartyList.push(partyDtoToModel(childData));
      });
      this.setdataLoaded(PartyList);
      //ItemList = snapshot.val()
    });
  }
}
