import React, { useEffect, useState } from "react";
import { Table, Input, Spin, Typography } from "antd";
import { BarcodeOutlined } from "@ant-design/icons";
import ItemModel from "model/ItemModel";
import { ItemsListViewProps } from "types";

const { Text } = Typography;

export default function ItemsListView({
  items,
  barcodevalue,
  onSelectItem,
  setVisibleBarcode,
}: ItemsListViewProps) {
  const [page, setpage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const [filteredList, setfilteredList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (items.length > 0) setLoading(false);
    setfilteredList(items);
  }, [items]);

  const filtered = (value) => {
    setsearchText(value);
    if (value) {
      const re = new RegExp(value, "i");
      let updatedList = items;
      let ids = [];
      for (let i = 0; i < updatedList.length; i++) {
        const element = updatedList[i];
        ids.push(element.Id);
        delete element.Id;
      }
      var filtered = updatedList.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val === "string" && val.match(re)
        )
      );
      for (let i = 0; i < updatedList.length; i++) {
        const element = updatedList[i];
        element.Id = ids[i];
      }
      setfilteredList(filtered);
    } else {
      setfilteredList(items);
    }
  };

  useEffect(() => {
    if (barcodevalue) {
      filtered(barcodevalue);
      setsearchText(barcodevalue);
    }
  }, [barcodevalue]);

  const columns: any = [
    // {
    //   title: "Index",
    //   key: "index",
    //   render: (text, record, index) => (page - 1) * 10 + index + 1,
    // },
    {
      title: "Sku",
      dataIndex: "Sku",
      key: "sku",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => a.Name.localeCompare(b.Name),
      },
      render: (text, record, index) => {
        return {
          props: {
            style: { color: "blue" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Stock",
      dataIndex: "Stock",
      key: "stock",
      render: (text, record: ItemModel) => (record.Stock ? record.Stock.ItemCount : ""),
    },
    {
      title: "Sale Price",
      dataIndex: "SalePrice",
      key: "sp",
      render: (text) => <b>{text}</b>,
    },
  ];
  return (
    <>
      <Input
        placeholder={"Search items..."}
        style={{ marginBottom: 20 }}
        value={searchText}
        onChange={(e) => filtered(e.target.value)}
        // suffix={
        //   <BarcodeOutlined
        //     style={{ cursor: "pointer" }}
        //     onClick={() => setVisibleBarcode(true)}
        //   />
        // }
      />
      <Table
        dataSource={filteredList}
        columns={columns}
        scroll={{ y: 550 }}
        pagination={false}
        loading={{ indicator: <Spin />, spinning: loading }}
        onRow={(record) => {
          return {
            onClick: (event) => {
              onSelectItem(record);
            },
          };
        }}
      />
    </>
  );
}
