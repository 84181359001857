import firebase from "utils/Firebase";
import ServiceBase from "services/ServiceBase";
import { ReceiptItemTable } from "utils/Constants";
import { jsonIgnoreReplacer, jsonIgnore } from "json-ignore";
import {
  DeleteReceiptItem,
  LoadReceiptItemsofReceipt,
} from "repo/ReceiptItemRepo";
import { ReceiptItemModel } from "model/ReceiptItemModel";

export class AllReceiptItemLoader extends ServiceBase {
  action(receiptid: any) {
    LoadReceiptItemsofReceipt(
      receiptid,
      (receiptitemsList) => this.setdataLoaded(receiptitemsList),
      (error) => this.handleError(error)
    );
  }
}
export class DeleteReceiptItemsService extends ServiceBase {
  action(receiptItems: Array<ReceiptItemModel>) {
    receiptItems.forEach((element) => {
      DeleteReceiptItem(element);
    });
  }
}
