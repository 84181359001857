export enum ReceiptStatus {
  Started = 0,
  Installment = 5,
  Done = 10,
}

export class ReceiptDto {
  // npm typescipt-json-serializer has bug and it was not converting anything and throwing exception
  // @JsonProperty('pp') purchasePrice : number;

  // constructor() {
  //   this._d = 0;
  // }

  // // get Id(): string {
  // //     return this.id;
  // // }
  // private id: string;
  // set Id(id: string) {
  //   this.id = id;
  // }
  // get Id(): string {
  //   return this.id;
  // }

  // private refNum: string;
  // public get RefNum(): string {
  //   return this.refNum;
  // }
  // public set RefNum(v: string) {
  //   this.refNum = v;
  // }

  // private pid: string; // partyid
  // public get PartyId(): string {
  //   return this.pid;
  // }
  // public set PartyId(v: string) {
  //   this.pid = v;
  // }

  // private d: string; //date
  // public get Date(): string {
  //   return this.d;
  // }
  // public set Date(v: string) {
  //   this.d = v;
  // }

  // private _d: number; //date
  // public get DateNum(): number {
  //   return this._d;
  // }
  // public set DateNum(v: number) {
  //   this._d = v;
  // }

  // private bd: number = 0;
  // public get BillDiscount(): number {
  //   return this.bd;
  // }
  // public set BillDiscount(v: number) {
  //   this.bd = v;
  // }

  // private sl: number = 0; //sale
  // public get Sale(): number {
  //   return this.sl;
  // }
  // public set Sale(v: number) {
  //   this.sl = v;
  // }

  // private ret: number = 0;
  // public get Ret(): number {
  //   return this.ret;
  // }
  // public set Ret(v: number) {
  //   this.ret = v;
  // }

  // private net: number = 0;
  // public get Net(): number {
  //   return this.net;
  // }
  // public set Net(v: number) {
  //   this.net = v;
  // }

  // private is: boolean; // issale
  // public get isSale(): boolean {
  //   return this.is;
  // }
  // public set isSale(v: boolean) {
  //   this.is = v;
  // }

  // private pa: number = 0;
  // public get PaidAmount(): number {
  //   return this.pa;
  // }
  // public set PaidAmount(v: number) {
  //   this.pa = v;
  // }

  // private tpp: number = 0;
  // public get TotalPurchPrice(): number {
  //   return this.tpp;
  // }
  // public set TotalPurchPrice(v: number) {
  //   this.tpp = v;
  // }

  // //  Started(1), Installment(5), Done(10);
  // private st: ReceiptStatus = ReceiptStatus.Started; // default status = 1
  // public get Status(): ReceiptStatus {
  //   return this.st;
  // }
  // public set Status(v: ReceiptStatus) {
  //   this.st = v;
  // }

  // private ip: boolean;
  // public get IsDiscPercent(): boolean {
  //   return this.ip;
  // }
  // public set IsDiscPercent(v: boolean) {
  //   this.ip = v;
  // }

  // private rem: string;
  // public get Remarks(): string {
  //   return this.rem;
  // }
  // public set Remarks(v: string) {
  //   this.rem = v;
  // }

  // private ir: boolean;
  // public get ItemRecvd(): boolean {
  //   return this.ir;
  // }
  // public set ItemRecvd(v: boolean) {
  //   this.ir = v;
  // }

  public id: string;
  public refNum: string;
  public pid: string;
  public d: string;
  public _d: number;
  public bd: number;
  public sl: number;
  public ret: number;
  public net: number;
  public is: number;
  public pa: number;
  public tpp: number;
  public st: number;
  public ip: boolean;
  public rem: string;
  public ir: boolean;
  
}
