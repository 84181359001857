import firebase from "utils/Firebase";
import ServiceBase from "services/ServiceBase";
import { ReceiptItemTable } from "utils/Constants";
import { jsonIgnoreReplacer, jsonIgnore } from "json-ignore";
import { ReceiptItemDto } from "dto/ReceiptItemDto";
import { ReceiptItemModel, ReceiptItemModelExt } from "model/ReceiptItemModel";

// export class ReceiptItemDto {
//   private t: string;
//   isNew: number;

//   get type(): string {
//     return this.t;
//   }
//   set type(t: string) {
//     this.t = t;
//   }

//   private n: string; //itemStr
//   public get itemStr(): string {
//     return this.n;
//   }
//   public set itemStr(v: string) {
//     this.n = v;
//   }

//   private q: number; //quantity
//   public get quantity(): number {
//     return this.q;
//   }
//   public set quantity(v: number) {
//     this.q = v;
//   }

//   private dp: boolean; //date
//   public get isDiscPercent(): boolean {
//     return this.dp;
//   }
//   public set isDiscPercent(v: boolean) {
//     this.dp = v;
//   }

//   private sku: boolean;
//   public get SKU(): boolean {
//     return this.sku;
//   }
//   public set SKU(v: boolean) {
//     this.sku = v;
//   }

//   private di: number;
//   public get discount(): number {
//     return this.di;
//   }
//   public set discount(v: number) {
//     this.di = v;
//   }

//   private am: number;
//   public get netAmt(): number {
//     return this.am;
//   }
//   public set netAmt(v: number) {
//     this.am = v;
//   }

//   private pp: boolean;
//   public get purcPrice(): boolean {
//     return this.pp;
//   }
//   public set purcPrice(v: boolean) {
//     this.pp = v;
//   }

//   private iid: boolean;
//   public get itemId(): boolean {
//     return this.iid;
//   }
//   public set itemId(v: boolean) {
//     this.iid = v;
//   }

//   private id: string;
//   public get Id(): string {
//     return this.id;
//   }
//   public set Id(v: string) {
//     this.id = v;
//   }

//   private rid: string;
//   public get receiptID(): string {
//     return this.rid;
//   }
//   public set receiptID(v: string) {
//     this.rid = v;
//   }

//   private pr: number;
//   public get itemPrice(): number {
//     return this.pr;
//   }
//   public set itemPrice(v: number) {
//     this.pr = v;
//   }
// }

function FirebaseToPOJO(r: ReceiptItemDto) {
  let recptntItem: ReceiptItemModel = new ReceiptItemModel();
  recptntItem.type = r.t;
  recptntItem.itemStr = r.n;
  recptntItem.receiptID = r.rid;
  recptntItem.itemPrice = r.pr;
  recptntItem.Id = r.id;
  recptntItem.itemId = r.iid;
  recptntItem.purcPrice = r.pp;
  recptntItem.netAmt = r.am;
  recptntItem.discount = r.di;
  recptntItem.SKU = r.sku;
  recptntItem.isDiscPercent = r.dp;
  recptntItem.quantity = r.q;

  return recptntItem;
}

function ReceiptItemPojoToFirebase(r: ReceiptItemModel) {
  let ri: ReceiptItemDto = new ReceiptItemDto();

  ri.t = r.type;
  ri.n = r.itemStr;
  ri.rid = r.receiptID;
  ri.pr = r.itemPrice;
  // ri.id = r.Id;
  ri.iid = r.itemId;
  ri.pp = r.purcPrice;
  ri.am = r.netAmt;
  ri.di = r.discount;
  ri.sku = r.SKU;
  ri.dp = r.isDiscPercent;
  ri.q = r.quantity;

  return ri;
}

export function SaveReceiptItem(ri: ReceiptItemModelExt) {
  if(ri.isRemoved) return
  let recptItemRef = firebase.database().ref(ReceiptItemTable());
  let raw = ReceiptItemPojoToFirebase(ri);
  let js = JSON.parse(JSON.stringify(raw));

  if (ri.Id === undefined) {
    ri.Id = recptItemRef.push().key;
    recptItemRef.child(ri.Id).set(js);
  } else recptItemRef.child(ri.Id).set(js);
}

export function DeleteReceiptItem(ri: ReceiptItemModel) {
  let recptItemRef = firebase.database().ref(ReceiptItemTable());
 
  recptItemRef.child(ri.Id).remove();
}

export let ReceiptItemList: ReceiptItemModel[] = [];

export function LoadReceiptItemsofReceipt(receiptid, callback?, error?) {
  var ReceiptItem = firebase
    .database()
    .ref(ReceiptItemTable())
    .orderByChild("rid")
    .equalTo(receiptid);

  ReceiptItem.once("value", (snapshot) => {
    ReceiptItemList.length = 0; // clear ... ItemList cannot be reassigned as its imported from outside file
    snapshot.forEach((childSnapshot) => {
      let childData: ReceiptItemDto = childSnapshot.val();
      ReceiptItemList.push(FirebaseToPOJO(childData));
    });
    if (callback) callback(ReceiptItemList);
  }, err => error(err));
}
