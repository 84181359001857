import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import useFetcher from "utils/useFetcher";
//import { ReceiptsList } from "repo/ReceiptRepo";
import { AllReceiptsLoader } from "services/ReceiptService";
import { Button } from "antd";
import ReceiptsListView from "components/receipt/ReceiptsListView";
import useStyles from 'components/style'
import { useAppSelector } from "redux/hooks";
import { getAllReceipts } from 'redux/selectors'

export default function Receipts() {
  const navigate = useNavigate();
  const classes = useStyles();
  const receipts = useAppSelector(getAllReceipts);

  const [r, setReceipts, loadAllReceipts] = useFetcher(
    AllReceiptsLoader
  );

  useEffect(() => {
    console.log("receipts count = ", receipts.length);

    if (receipts.length) {
      setReceipts(receipts);
      return;
    }

    loadAllReceipts((r) => {
      setReceipts(r)
    });
  }, []);

  const handleAddReceipt = (e) => {
    navigate("/app/addreceipt");
  };

  console.log('rendering receipts', receipts)
 // if(refresh.current) refresh.current = false
 // if (!dataLoaded) return <h2>Loading receipts...</h2>;
//  else
    return (
      <>
        <div className={classes.heading}> All Receipts </div> 
        <Button className={classes.margin} type="primary" onClick={handleAddReceipt}>
          Add Receipt
        </Button>
        <ReceiptsListView receipts={receipts} history={navigate} loading={receipts ? false : true} />
      </>
    );
}
