import firebase from "utils/Firebase";
import { ItemTable } from "utils/Constants";
import ItemModel, { StockModel } from "model/ItemModel";
import ItemDto, { StockDto } from "dto/ItemDto";
import { firebaseRefs } from "utils/Globals";

// export class Item {
//   private id: string;
//   private sku: string;
//   private n?: string;
//   private sp: number;
//   private pp: number;
//   private st?: object;

//   // npm typescipt-json-serializer has bug and it was not converting anything and throwing exception
//   // @JsonProperty('pp') purchasePrice : number;

//   constructor() {
//     this.id = "-1";
//     //  this.bd = ""
//     this.sp = this.pp = 0;
//   }

//   get Id(): string {
//     return this.id;
//   }
//   set Id(id: string) {
//     this.id = id;
//   }

//   get Name(): string {
//     return this.n;
//   }
//   set Name(bd: string) {
//     this.n = bd;
//   }

//   get SalePrice(): number {
//     return this.sp;
//   }
//   set SalePrice(p: number) {
//     this.sp = p;
//   }

//   get PurchasePrice(): number {
//     return this.pp;
//   }
//   set PurchasePrice(p: number) {
//     this.pp = p;
//   }

//   get Sku(): string {
//     return this.sku;
//   }
//   set Sku(sku: string) {
//     this.sku = sku;
//   }
//   get Stock(): object {
//     return this.st;
//   }
//   set Stock(st: object) {
//     this.st = st;
//   }
// }

export let ItemList: ItemModel[] = [];

export function GetItemList() {
  return ItemList;
}

export function AddItem(newItem: ItemModel) {
  //    const des = deserialize<Item>(newItem, Item);

  let item: ItemModel = new ItemModel();
  item.Id = newItem.Id;
  // item.Name = newItem.: n
  // item.salePrice = newItem.sp
  // item.purchasePrice = newItem.pp

  ItemList.push(item);
}

function FirebaseToPOJO(dbItem: ItemDto, key) {
  let item: ItemModel = new ItemModel();
  item.Id = key;
  item.Sku = dbItem.sku;
  item.Name = dbItem.n;
  item.SalePrice = dbItem.sp;
  item.PurchasePrice = dbItem.pp;
  item.Desc = dbItem.ds
  item.isActive = dbItem.ia
  item.parentId = dbItem.pid
  item.hasSubitems = dbItem.hsi
  item.Date = dbItem.d
  item.categotyId = dbItem.cid
  item.brandId = dbItem.bd
  
  item.Stock = StockDtoToModel(dbItem.st)

  return item;
}

function PojoToFirebase(pojo: ItemModel) {
  let item: ItemDto = new ItemDto();
  item.id = pojo.Id;
  item.n = pojo.Name;
  item.sku = pojo.Sku;
  item.sp = pojo.SalePrice;
  item.pp = pojo.PurchasePrice;
  item.ds = pojo.Desc
  item.ia = pojo.isActive
  item.pid = pojo.parentId
  item.hsi = pojo.hasSubitems
  item.d = pojo.Date
  item.cid = pojo.categotyId
  item.bd = pojo.brandId
  item.st = pojo.Stock && StockModeltoDto(pojo.Stock)
  return item;
}

// Stock Model convertors
function StockDtoToModel(stockDto: StockDto) {
  if(!stockDto) return null
  let s: StockModel = new StockModel()
  s.Batch = stockDto.ba
  s.Cartons = stockDto.cr
  s.ExpDate = stockDto.expd
  s.ItemCount = stockDto.ct
  s.ItemsPerCarton = stockDto.ipc
  s.Location = stockDto.loc
  s.MfgDate = stockDto.mfgd
  return s
}

function StockModeltoDto(sm: StockModel){
  let s: StockDto = new StockDto()
  s.ba = sm.Batch
  s.cr = sm.Cartons
  s.expd = sm.ExpDate
  s.ct = sm.ItemCount
  s.ipc = sm.ItemsPerCarton
  s.loc = sm.Location
  s.mfgd = sm.MfgDate
  return s

}


// itemsRef
export function LoadAllItems(callback?, error?) {
  const itemsRef = firebase.database().ref(ItemTable());
  itemsRef.on(
    "value",
    (snapshot) => {
      ItemList = []; // clear .. new Array
      console.log("Items = ", snapshot.val());
      snapshot.forEach((childSnapshot) => {
        // key will be "ada" the first time and "alan" the second time
        let key = childSnapshot.key;
        // childData will be the actual contents of the child
        let childData = childSnapshot.val();
        ItemList.push(FirebaseToPOJO(childData, key));
      });
      // this.setdataLoaded(true);
      //ItemList = snapshot.val()
      if (callback) callback(ItemList);
    },
    (err) => error(err)
  );

  firebaseRefs.push(itemsRef)
}

export async function SaveItemRepo(item: ItemModel, callback?) {
  let raw = PojoToFirebase(item);
  // to remove undefined fields which give error in firebase DB save
  let js = JSON.parse(JSON.stringify(raw));

  let itemRef = firebase.database().ref(ItemTable());

  try {
    if(item.Id === undefined) {
      item.Id = itemRef.push().key;
    
      // error handling in firebase function
      //https://firebase.google.com/docs/functions/terminate-functions
      //https://stackoverflow.com/questions/47652149/best-way-to-capture-error-from-database-firebase-javascript
      await itemRef.child(item.Id).set(js, (err) => {
        if (err && callback) callback(err); // if no permission
      });
    }
    else{
      await itemRef.child(item.Id).update(js, (err) => {
        if (err && callback) callback(err);
      });
    }

    if (callback) callback("success");
  } catch (e) {
    console.log(e);
    if (callback) callback("fail");
  }
}
