import React, { useState, useEffect, useContext } from "react";
import Login from "components/Login";
import firebase from "utils/Firebase";
import { SetIsLoggedIn } from "utils/Globals";
import Dashboard from "components/Dashboard";
import { Layout, Menu, Row, Col } from "antd";
import { useNavigate } from "react-router";
import { HandleLogout } from "utils/Globals";
import NavBar from "layout/NavBar";
import TopBar from "layout/TopBar";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { CompanyTable } from "utils/Constants";
import CompanyModel from "model/CompanyModel";
import { useAppDispatch } from "redux/hooks";
import { companyData } from "redux/reducers/rootReducer";
import { LogContext } from "utils/GlobalLogger";

const { Header } = Layout;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

export const LayoutAuthenticated = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [loggedIn, setloggedIn] = useState(null);
  const [logger, logSessionEnd] = useContext(LogContext);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      console.log("login state = ", user);
      if (user) {
        setloggedIn(true);
        SetIsLoggedIn(true);
      } else {
        setloggedIn(false);
        SetIsLoggedIn(false);
      }
    });

    const company = firebase.database().ref(CompanyTable());

    company.once("value", (snapshop) => {
      let cData: CompanyModel = snapshop.val();
      console.log("data", cData);
      dispatch(companyData(cData));
    });

    return () => { logSessionEnd() };
  }, []);

  if (loggedIn === null) return <div>Loading...</div>;
  if (loggedIn === false) {
    navigate("/login");
    return null;
  }

  //return <div className="App">{component}</div>;

  const handleLogout = (e) => {
    HandleLogout(navigate);
  };

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>

    // <Layout>
    //       <Header className="header">
    //         <div className="logo" />
    //         <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
    //           <Menu.Item key="1">POS</Menu.Item>
    //           <Menu.Item key="2" onClick={handleLogout}>
    //             Logout
    //           </Menu.Item>
    //         </Menu>
    //       </Header>
    //       <Layout style={{ minHeight: "100vh", backgroundColor: "#111" }}>
    //         <Row lg={24} style={{ minHeight: "90vh", backgroundColor: "#0f0" }}>
    //           <Col
    //             lg={3}
    //             xs={6}
    //             style={{ minHeight: "90vh", backgroundColor: "#f00" }}
    //           >
    //             <UserSideBar />
    //           </Col>
    //           <Col lg={21} style={{ minHeight: "90vh", backgroundColor: "#00f" }}>
    //             <Layout style={{ padding: 24 }}>{props.children}</Layout>
    //           </Col>
    //         </Row>
    //       </Layout>
    //     </Layout>
  );
};
