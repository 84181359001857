export class ReceiptItemModel {
  public type: string;
  public itemStr: string;
  public receiptID: string;
  public itemPrice: number;
  public Id: string;
  public itemId: string;
  public purcPrice: number;
  public netAmt: number;
  public discount: number;
  public SKU: string;
  public isDiscPercent: boolean;
  public quantity: number;
  public isNew: number;
}

export class ReceiptItemModelExt extends ReceiptItemModel {
  public isRemoved: boolean
}
