import React, { useState } from "react";
import { Input, Table } from "antd";
import { useNavigate } from "react-router/";
import { ItemsListProps } from "types";
import ItemModel from "model/ItemModel";
import useStyles from "components/style";

export default function ItemsListView(props: ItemsListProps) {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState(null)
  const classes = useStyles();

  const columns: any = [
    {
      title: "Index",
      key: "index",
      width: "5%",
      render: (text, record, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Sku",
      dataIndex: "Sku",
      key: "sku",
      width: "20%",
      sorter: {
        compare: (a, b) => a.Sku - b.Sku,
      },
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
      width: "35%",
      defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) => a.Name.localeCompare(b.Name),
      },
    },
    // {
    //   title: "Purchase Price",
    //   dataIndex: "PurchasePrice",
    //   key: "pp",
    // },
    {
      title: "Stock",
      dataIndex: "Stock",
      width: "20%",
      key: "stock",
      render: (text, record: ItemModel) =>
        record.Stock ? record.Stock.ItemCount : "",
    },
    {
      title: "Sale Price",
      dataIndex: "SalePrice",
      key: "sp",
      width: "20%",
      render: (text) => <b>{text}</b>,
      sorter: {
        compare: (a, b) => a.SalePrice - b.SalePrice,
      },
    },
  ];

  // exclude Id and Date from Items data
  function omit(obj) {
    const { Id, Date, ...rest } = obj;
    return rest;
  }

  const Search = (value) => {
    let v = value.target.value
    const baseData = props.items;

    const filterTable = baseData.filter((o) =>
      Object.keys(omit(o)).some((k) =>
        String(o[k]).toLowerCase().includes(v.toLowerCase())
      )
    );

    setFiltered(filterTable)
  };

  return (
    <>
      <Input.Search
        placeholder="Search item..."
        onChange={Search}
        className={classes.padded}
      />

      <Table
        dataSource={filtered ? filtered : props.items}
        sortDirections={['ascend', 'descend', 'ascend']}
        columns={columns}
        pagination={{
          onChange(current) {
            setPage(current);
          },
          position: ["bottomRight"],
          showSizeChanger: true,
          pageSizeOptions: ["20", "50", "100", "200"],
        }}
        onRow={(record) => {
          return {
            onClick: (event) => {
              navigate("/app/additem", { state: record });
            },
          };
        }}
      />
    </>
  );
}
