import React, { useEffect, useState } from "react";
import { SetCompanyId, SetIsLoggedIn } from "utils/Globals";
import firebase, { log_db, reports_db } from "../utils/Firebase";
import "firebase/auth";
import "firebase/database";
import { Paper, TextField } from "@material-ui/core";
import { useInput } from "components/utility/useInput";
import { useNavigate } from "react-router";
import { Form, Row, Col, Input, Button, Spin, notification, Card } from "antd";
import "./Login.css";
import Cookies from "js-cookie";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   return () => {};
  // }, []);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Login form has errors:", errorInfo);
  };
  const handleSubmit = async (values) => {
    // e.preventDefault();
    setIsLoading(true);
    try {
      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password);

      // need to auth all other databases, otherwise permission denied error
      log_db.auth().signInWithEmailAndPassword(values.email, values.password);
      reports_db
        .auth()
        .signInWithEmailAndPassword(values.email, values.password);

      Cookies.set("valid", "true", { expires: 7 }); // 7 days expiry

      const uid = response.user.uid || 0;

      firebase
        .database()
        .ref(`/Users/${uid}`)
        .once("value", (snapshot) => {
          const { CompanyId } = snapshot.val() || 0;
          SetCompanyId(CompanyId);
          SetIsLoggedIn(true);
          setTimeout(() => {
            notification.success({ message: "Success" });
            navigate("/"); //redirect to main page
            setIsLoading(false);
          }, 100); // post after a slight delay as maybe islogged in isnt set
        });
      console.log(response);
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.code,
        description: error.message,
      });

      setIsLoading(false);
    }
  };

  console.log("in login page ");

  return (
    <>
      <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
        {/* <Col span={4}> */}
        <Card title="Login" style={{ width: 300 }}>
          <Form
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
            initialValues={{
              email: "",
              password: "",
            }}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              {/* Used TextField from material ui bcz it has better text input with inline label */}
              <TextField
                label="Email"
                id="email"
                style={{ marginBottom: 12 }}
              />
            </Form.Item>

            <Form.Item
              // label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password",
                },
              ]}
            >
              <TextField
                label="Password"
                type="password"
                id="password"
                style={{ marginBottom: 12 }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading} // makes it disabled with spinner
                className="login-form-button"
              >
                Login
              </Button>
            </Form.Item>
          </Form>

          {/* {isLoading && <Spin size="large" />} */}
        </Card>
        {/* </Col> */}
      </Row>
    </>
  );
};

export default Login;
