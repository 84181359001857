import React, { useCallback } from "react";

const useReceiptBusiness = () => {
	const calculateBillAmounts = useCallback(
		(discountType, quantity, discount, itemPrice, receiptType?) => {
			let netAmount1;
			if (discountType === "amount") {
				netAmount1 = quantity * itemPrice - discount;
			} else {
				let percent = 1 - discount / 100;
				netAmount1 = quantity * itemPrice * percent;
			}

			if (receiptType === "Return") {
				netAmount1 *= -1;
			}

			return netAmount1
		},
		[],
	)

	return [calculateBillAmounts];
};

export default useReceiptBusiness;
