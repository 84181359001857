import React, { useState, useEffect, useCallback } from "react";
import {
 Modal, Form, Radio, Input, Select 
} from "antd";
import useReceiptBusiness from "business/receipt/Receipt";

const { Option } = Select;

function UpdateItemsForm({
  visible,
  onUpdate,
  onCancel,
  confirmLoading,
  data,
}) {
  const [quantity, setQuantity] = useState(data.quantity);
  const [netAmt, setNetAmount] = useState(data.netAmt);
  const [discount, setDiscount] = useState(data.discount);
  const [receiptType, setReceiptType] = useState(data.type);
  const [discountType, setDiscountType] = useState(
    data.isDiscPercent ? "percent" : "amount");
  const [calculateAmounts] = useReceiptBusiness();

  useEffect(() => {
    onChangeDiscount();
    const amt = calculateAmounts(discountType, quantity, discount, data.itemPrice, receiptType)

    setNetAmount(amt)
  }, [discount, discountType, receiptType, quantity]);

  const onChangeDiscount = () => {
    let netAmount1;
    if (discountType === "amount") {
      netAmount1 = quantity * data.itemPrice - discount;
    } else {
      const percent = 1 - discount / 100;
      netAmount1 = quantity * data.itemPrice * percent;
    }

    if (receiptType === "Return") {
      netAmount1 *= -1;
    }

    setNetAmount(netAmount1);
  };

  const handleUpdate = () => {
    onUpdate(quantity, receiptType, discountType, discount, netAmt);
  };

  if (data === null) return null;

  return (
    <Modal
      visible={visible}
      title="Update Item"
      okText="Update"
      onCancel={onCancel}
      onOk={() => handleUpdate()}
      confirmLoading={confirmLoading}
    >
      <Form>
        <Form.Item label="Item Name">{data.itemStr}</Form.Item>
        <Form.Item label="Quantity">
          <Input
            placeholder="Quantity"
            id="Quantity"
            value={quantity}
            onChange={(event) => {
              setQuantity(Number(event.target.value));
            }}
          />
        </Form.Item>
        <Form.Item label="Per Item Price">
          <Input placeholder="Price per item" value={data.itemPrice} disabled />
        </Form.Item>
        <Form.Item label="Receipt Type">
          <Select
            placeholder="Receipt Type"
            value={receiptType}
            onChange={(e) => { setReceiptType(e) }}
          >
            <Option value="Sale">Sale</Option>
            <Option value="Return">Return</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Discount">
          <Input
            placeholder="Discount"
            id="discount"
            value={discount}
            onChange={(value) => {
              setDiscount(Number(value.target.value));
            }}
          />
        </Form.Item>
        <Form.Item>
          <Radio.Group
            onChange={(e) => {
              setDiscountType(e.target.value);
            }}
            value={discountType}
          >
            <Radio value="amount">Amount</Radio>
            <Radio value="percent">%</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Net Amount">
          <Input placeholder="Net Amount" value={netAmt} disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateItemsForm;
