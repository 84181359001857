import React, { useCallback, useEffect, useState } from "react";
import LoggingService from "services/LoggingService";
import { LogMessageType } from "./Globals";

type CallbackType = (tag: number, message?: LogMessageType) => void;

const useLogger = () => {
  const [logger, setLogger] = useState<LoggingService>(null);

  useEffect(() => {
    if (!logger) setLogger(new LoggingService());
  }, []);

  const log = useCallback<CallbackType>((tag, message) => {
    logger.log(tag, message);
  }, [logger]);

  const logSessionEnd = useCallback(() => {
    if(logger)
      logger.logSessionEnd();
  }, [logger]);


  return [log, logSessionEnd] as const;
};

export default useLogger;
