import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ItemsListView from "components/item/ItemsListView";
import CompanyModel from "model/CompanyModel";
import ItemModel from "model/ItemModel";
import { ReceiptModel } from "model/ReceiptModel";

type rootState = {
  value: number;
  company: CompanyModel;
  receiptsList: ReceiptModel[];
  itemsList: ItemModel[];
};

export const initialState = {
  value: 0,
  company: {},
  receiptsList: [],
  itemsList: [],
} as rootState

export const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    companyData: (state, data: PayloadAction<CompanyModel>) => {
      state.company = data.payload;
    },
    setReceiptsList: (state, data: PayloadAction<ReceiptModel[]>) => {
      state.receiptsList = data.payload;
    },
    setItemsList: (state, data: PayloadAction<ItemModel[]>) => {
      state.itemsList = data.payload;
    },
    logout: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
     // state = initialState;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { companyData, setReceiptsList, setItemsList, incrementByAmount, logout } = rootSlice.actions;

export default rootSlice.reducer;
