import React, { useRef, useEffect, useState } from "react";
import {
  Form,
  Input,
  Radio,
  Modal,
  Button,
  DatePicker,
  Empty,
  Card,
  Row,
  Col,
} from "antd";
import { Grid, makeStyles, TextField, Hidden, setRef } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import firebase from "utils/Firebase";
import {
  AllReceiptsLoader,
  CheckoutReceiptService,
  SaveReceiptService,
  UpdateReceiptPaidAmount,
} from "services/ReceiptService";
import {
  ReceiptTable,
  PartyTable,
  ReceiptNotificationTable,
  ReceiptPaymentTable,
  ReceiptItemTable,
} from "utils/Constants";
import { ItemList, GetItemList } from "repo/ItemRepo";
import { AllItemsLoader } from "services/ItemService";
import {
  AllReceiptItemLoader,
  DeleteReceiptItemsService,
} from "services/ReceiptItemService";
import { SavePartyRepo, PartyList, AllPartiesLoader } from "repo/PartyRepo";
import {
  CreateReceiptNotification,
  SaveReceiptNotification,
} from "repo/ReceiptNotificationRepo";
import useFetcher, { useSender } from "utils/useFetcher";
import ItemsListView from "./ItemsListView";
import CheckoutModal from "./Checkout";
import {
  RightOutlined,
  DeleteFilled,
  PlusSquareOutlined,
  MinusSquareOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import moment from "moment";
import BarCodeScan from "./BarCodeScan";
import UpdateItemsForm from "./UpdateItemsForm";
import { ReceiptModel } from "model/ReceiptModel";
import { dateFormat, dateStringFormat } from "utils/Globals";
import { ReceiptItemModel, ReceiptItemModelExt } from "model/ReceiptItemModel";
import { SavePartyService } from "services/PartyService";
import PartyModel from "model/PartyModel";
import ItemModel from "model/ItemModel";
import { useNavigate, useLocation } from "react-router";
import useReceiptBusiness from "business/receipt/Receipt";

const useStyles = makeStyles((theme) => ({
  list: {
    border: "1px solid grey",
    height: "auto",
    borderRadius: 8,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 10,
    alignItems: "center",
  },
  heading: {
    margin: 10,
    fontSize: 24,
    fontWeight: 500,
  },
  saleText: {
    fontSize: 10,
    marginRight: 50,
  },
  itemName: {
    fontSize: 16,
    cursor: "pointer",
  },
  deleteIcon: {
    marginRight: 0,
    marginLeft: 20,
    color: "#1890ff",
    backgroundColor: "#f0f2f5",
  },
  itemsTableHead: {
    backgroundColor: "#fff",
    height: 40,
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 20,
  },
  discountarea: {
    textAlign: "center",
    width: "100%",
    background: "#eeeeee",
    marginBottom: 20,
  },
  padded: {
    padding: 10,
    // paddingLeft: 10,
    // paddingRight: 10,
    // marginBottom: 20,
  },
  paddedBottom: {
    paddingBottom: 20,
    // paddingLeft: 10,
    // paddingRight: 10,
    // marginBottom: 20,
  },
  paddedRight: {
    paddingRight: 10,
    display: 'inline'
  },
  showItems: {
    cursor: "pointer",
  },
  checkout: {
    marginRight: 20,
  },
  customerInput: {
    backgroundColor: "#fff",
    height: 35,
    border: "1px solid lightgrey",
    fontSize: 13,
    marginTop: -0,
    padding: 5,
    borderRadius: 2,
  },
}));

export default function AddEditReceipt(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [receiptData, setReceiptData] = useState<ReceiptModel>(
    state ? (state as ReceiptModel) : new ReceiptModel()
  );
  const [items, setItems, loadAllItems] = useFetcher(AllItemsLoader);
  const [receiptItems, setReceiptItems, loadAllReceiptItems] = useFetcher<
    Array<ReceiptItemModelExt>
  >(AllReceiptItemLoader, []);
  const [dataLoaded2, setdataLoaded2, loadAllReceipts] = useFetcher(
    AllReceiptsLoader
  );
  const [parties, setParties, loadAllParties] = useFetcher<Array<PartyModel>>(
    AllPartiesLoader
  );
  const [recptSaved, updateReceiptPaidAmountService] = useSender(
    UpdateReceiptPaidAmount
  );
  const [saver, saveReceiptService] = useSender(SaveReceiptService);
  const [del, deleteReceiptItemsService] = useSender(DeleteReceiptItemsService);
  const [checkout, checkoutReceiptService] = useSender(CheckoutReceiptService);
  const [isPartySaved, savePartyService] = useSender(SavePartyService);

  const [selectedReceiptItem, setSelectedReceiptItem] = useState(null);
  const [showItemModal, setShowItemModal] = useState(false);
  const [netAmount, setNetAmount] = useState(0);
  const [bill, setBill] = useState(0);
  const [finalNetAmount, setFinalNetAmount] = useState(0);
  const [discountType, setDiscountType] = useState("amount");
  const [overAlldiscountType, setoverAlldiscountType] = useState("amount");
  const [discount, setDiscount] = useState(0);
  const [overAllDiscount, setoverAllDiscount] = useState(0);
  const [returnAmount, setreturnAmount] = useState(0);
  const [party, setParty] = useState([]);
  const [partyId, setPartyId] = useState("");
  const [date, setDate] = useState(moment().format());
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);
  const [showItems, setShowItems] = useState(true);
  const [visibleBarcode, setVisibleBarcode] = useState(false);
  const [receiptType, setReceiptType] = useState("Sale");
  const [barcodevalue, setBarcodeValue] = useState("");
  const [visibleCheckoutModal, setVisibleCheckoutModal] = useState(false);
  const [visiblePayInstallmentModal, setVisiblePayInstallmentModal] = useState(
    false
  );
  const [installmentAmount, setInstallmentAmount] = useState(0);
  const [confirmLoadingCheckout, setConfirmLoadingCheckout] = useState(false);
  const [refNum, setRefNum] = useState("");
  const [newParty, setNewParty] = useState("");
  const [partyName, setPartyName] = useState("");
  const [ReceiptId, setReceiptId] = useState("");
  const [Mode, setMode] = useState(0);
  const [PaidAmount, setPaidAmount] = useState(0);
  const [deletedReceiptItems, setDeletedReceiptItems] = useState<
    Array<ReceiptItemModel>
  >([]);
  // const [ReceiptItems, setReceiptItems] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [calculateAmounts] = useReceiptBusiness();
  const formRef = useRef(null);
  const classes = useStyles();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const onChangeDiscountType = (e) => {
    setDiscountType(e.target.value);
  };

  console.log("rendering...");
  useEffect(() => {
    console.log("mounted");
    return () => console.log("unmount");
  }, []);

  useEffect(() => {
    calculateBill();
  }, [receiptItems]);

  useEffect(() => {
    if (!PartyList.length) loadAllParties();
    else setParties(PartyList);

    if (GetItemList().length === 0) {
      loadAllItems();
    }
    // setItemsLoaded(true);
  }, []);

  useEffect(() => {
    if (receiptData.Id !== undefined) {
      // this will set receiptItems state var automatically
      setReceiptId(receiptData.Id);
      loadAllReceiptItems(null, receiptData.Id);

      // setMode(receiptData.Status);

      setRefNum(receiptData.RefNum);

      setBill(
        receiptData.IsDiscPercent
          ? receiptData.Net + receiptData.BillDiscount
          : Math.round(receiptData.Net + (receiptData.BillDiscount / 100) * 100)
      );
      //      setReceiptId(receiptData.Id);
      setoverAlldiscountType(receiptData.IsDiscPercent ? "percent" : "amount");
      setDate(moment(receiptData.Date).format());
      setShowItems(!isReadOnly());
    } else {
      // generate receipt id on new case
      // let id = firebase.database().ref(ReceiptTable).ref.push().key;
      // receiptData.Id = id;
      // setReceiptId(id);
    }
  }, [receiptData]);

  useEffect(() => {
    setRefresh(true);
  }, [items]);

  useEffect(() => {
    if (!parties || parties.length == 0) return;

    if (receiptData.PartyId) {
      let pa: PartyModel = parties.find(
        (p: PartyModel) => p.Id === receiptData.PartyId
      );
      if (pa) {
        receiptData.PartyId = pa.Id;

        setPartyId(pa.Id);
        setPartyName(pa.Name);
      }
    } else {
      receiptData.PartyId = parties[0].Id;
      setPartyId(parties[0].Id);
      setPartyName(parties[0].Name);
    }

    setRefresh(true);
  }, [parties]);

  const isReadOnly = () => {
    return receiptData.Status == 5 || receiptData.Status == 10;
  };

  const snapshotToArray = (snapshot) =>
    Object.entries(snapshot).map((e) => {
      Object.assign(e[1], { uid: e[0] });
    });

  const getParty = () => {
    setLoading(true);
    const db = firebase.database();
    db.ref(PartyTable()).once("value", (snapshot) => {
      if (snapshot.val()) {
        setParty(snapshotToArray(snapshot.val()));
        setLoading(false);
      } else {
        setParty([]);
        setLoading(false);
      }
    });
  };

  const navigateBack = () => {
    navigate(-1)
  }
  const totalPurchasePrice = () => {
    var tpp = 0;
    for (let i = 0; i < receiptItems.length; i++) {
      const element = receiptItems[i];
      if (element.purcPrice) tpp = tpp + element.purcPrice;
    }
    return tpp;
  };

  const routeChange = () => {
    props.history.push("/receipts");
  };

  const createNewParty = (partyName) => {
    let partyValue: PartyModel = new PartyModel();
    partyValue.DueAmount = 0;
    partyValue.IsCust = 1;
    partyValue.IsVendor = 0;
    partyValue.Name = partyName;

    savePartyService(null, partyValue);

    //return SavePartyService(partyValue);
  };

  const PopulateReceiptDto = () => {
    //   let recpt = new ReceiptDto();
    receiptData.RefNum = refNum ? refNum.toString() : "";
    receiptData.PartyId = partyId;
    receiptData.DateNum = moment(date).unix() * 1000;
    receiptData.Date = moment(date).format(dateStringFormat);
    //    receiptData.BillDiscount = BillDiscount;
    //    receiptData.Sale = Number(bill);
    //    receiptData.Ret = Number(returnAmount);
    //    receiptData.Net = Net;
    receiptData.isSale = 1;
    // receiptData.PaidAmount = Number(customerPaid);
    receiptData.TotalPurchPrice = totalPurchasePrice();
    //   receiptData.Status = Status;
    //receiptData.IsDiscPercent =
    //  overAlldiscountType === "percent" ? true : false;
    // receiptData.Remarks = remarks;
    receiptData.ItemRecvd = false;
    return receiptData;
  };

  const handleSave = () => {
    PopulateReceiptDto();
    saveReceiptService(null, receiptData, receiptItems);
    if (deletedReceiptItems.length > 0)
      deleteReceiptItemsService(deletedReceiptItems);
    //saveNewReceiptItems();
    navigate(-1);
  };

  const handleCheckout = (isCredit: boolean, amountPaid: number) => {
    PopulateReceiptDto();
    checkoutReceiptService(
      null,
      isCredit,
      amountPaid,
      receiptData,
      receiptItems
    );
    setVisibleCheckoutModal(false);
    navigate(-1);

    // if (amountPaid > receiptData.Net) amountPaid = receiptData.Net; // if paid more, rest shld be given as change, not saved as paid amt
    // receiptData.PaidAmount = amountPaid;

    // if (isCredit) {
    //   receiptData.Status = ReceiptStatus.Installment;
    //   let recNotification: ReceiptNotificationDto = CreateReceiptNotification()
    //   recNotification.PartyId = partyId
    //   recNotification.DueAmount = receiptData.Net - receiptData.PaidAmount;
    //   SaveReceiptNotification(receiptData.Id, recNotification)
    // } else {
    //   receiptData.Status = ReceiptStatus.Done;
    //   if (amountPaid < receiptData.Net) receiptData.Net = amountPaid;
    // }

    // receiptData.PaidAmount = amountPaid
    // handleSave(); // save receipt

    // let rePayment = CreateReceiptPaymentDto()
    // rePayment.ReceiptId = receiptData.Id
    // rePayment.Amount = amountPaid
    // SaveReceiptPayment(rePayment);
  };

  const handleInstallment = () => {
    PopulateReceiptDto();
    updateReceiptPaidAmountService(null, receiptData, installmentAmount);
    setVisiblePayInstallmentModal(false);
    navigate(-1)
  };

  const handleSearch = async (value) => {
    for (let k = 0; k < parties.length; k++) {
      const element2 = parties[k];
      if (element2.Name === value) {
        setPartyId(element2.Id);
      }
    }
    setNewParty("");
  };

  const handlePartyChanged = (value) => {
    setPartyId("");
    setNewParty(value.target.value);
  };

  function receiptItemsHasItem(itemid): ReceiptItemModel | undefined {
    return receiptItems.find((ri) => ri.itemId === itemid);
  }

  const onSelectItem = (item: ItemModel) => {
    const existingRI = receiptItemsHasItem(item.Id);
    if (existingRI) {
      existingRI.quantity++;
      existingRI.netAmt = calculateAmounts(
        existingRI.isDiscPercent,
        existingRI.quantity,
        existingRI.discount,
        existingRI.itemPrice,
        existingRI.type
      );
      calculateBill();
      setRefresh(true);
    } else {
      const ri = new ReceiptItemModelExt();
      ri.itemId = item.Id;
      ri.itemStr = item.Name;
      ri.quantity = 1;
      ri.isDiscPercent = false;
      ri.SKU = item.Sku;
      ri.discount = 0;
      ri.netAmt = ri.quantity * item.SalePrice;
      ri.purcPrice = item.PurchasePrice;
      //    ri.receiptID = receiptData.Id;
      ri.itemPrice = item.SalePrice;
      ri.type = "Sale"; // its a sale recpt and default is 'Sale', not 'Return'
      ri.isNew = 1;

      // let recptItemRef = firebase.database().ref(ReceiptItemTable);
      // ri.Id = recptItemRef.push().key;

      //    setNetAmount(netAmount + ri.itemPrice);
      // item.netAmount = item.itemPrice;
      //    setBill(bill + ri.itemPrice);

      //receiptData.Net += ri.itemPrice;
      //receiptData.Sale += ri.itemPrice;

      setDiscount(ri.discount);
      // setBarcodeValue("");
      setReceiptItems([...receiptItems, ri]);
    }
  };

  const calculateBill = () => {
    let billamount = 0;
    if (receiptItems === undefined) return;

    receiptItems.forEach((ri) => {
      if (!ri.isRemoved && ri.netAmt) billamount += ri.netAmt;
    });

    //   setBill(billamount);
    receiptData.Sale = billamount;

    let disc = 0;
    if (receiptData.BillDiscount) {
      if (receiptData.IsDiscPercent === true)
        disc = (receiptData.BillDiscount / 100) * billamount;
      else disc = receiptData.BillDiscount;
    }

    receiptData.Net = billamount - disc;

    setRefresh(true);
  };

  // useEffect(() => {
  //   calculateBill();
  // }, [receiptData.BillDiscount, overAlldiscountType]);

  const onDeleteList = (index) => {
    // if (receiptItems[index].receiptType === "Return") {
    //   receiptData.Ret -= Math.abs(receiptItems[index].netAmount);
    //   // setBill(bill - receiptItems[index].sp)
    // } else {
    //   receiptData.Net -= receiptItems[index].itemPrice;
    //   setBill(bill - receiptItems[index].itemPrice);
    // }
    // if (Mode === 1 && receiptItems[index].id) {
    //   let deleteItemRef = firebase
    //     .database()
    //     .ref(ReceiptItemTable + "/" + receiptItems[index].id);
    //   deleteItemRef.remove();
    // }
    // receiptItems.splice(index, 1);

    // if (receiptItems) {
    //   setReceiptItems([...receiptItems]);
    // }

    // if ritem not saved yet, just remove it and dont add it in deletedlist, as its not saved anyway
    if (receiptItems[index].Id !== undefined)
      setDeletedReceiptItems([...deletedReceiptItems, receiptItems[index]]);

    receiptItems.splice(index, 1);
    // setRefresh(true);
    setReceiptItems([...receiptItems]); // so that calculatebill() gets called, .splice doesnt call the effect
  };

  const onDecrementQuant = (index) => {
    const ri = receiptItems[index];
    ri.quantity--;
    ri.netAmt = calculateAmounts(
      ri.isDiscPercent,
      ri.quantity,
      ri.discount,
      ri.itemPrice,
      ri.type
    );
    calculateBill();
    setRefresh(true);
  };

  const onIncrementQuant = (index) => {
    const ri = receiptItems[index];
    ri.quantity++;
    ri.netAmt = calculateAmounts(
      ri.isDiscPercent,
      ri.quantity,
      ri.discount,
      ri.itemPrice,
      ri.type
    );
    calculateBill();
    setRefresh(true);
  };

  const openModal = (record) => {
    setSelectedReceiptItem(record);
    // setQuantity(record.quantity ? record.quantity : quantity);
    // setReceiptType(record.type ? record.type : receiptType);
    // setDiscount(record.discount ? record.discount : discount);
    // setDiscountType(record.isDiscPercent ? 'percent' : 'amount');
    // setNetAmount(record.netAmt ? record.netAmt : netAmount);
    setShowItemModal(true);
  };

  const onUpdateReceiptItem = (
    quantity,
    receiptType,
    discountType,
    discount,
    netAmt
  ) => {
    selectedReceiptItem.quantity = quantity;
    selectedReceiptItem.netAmt = netAmt;
    selectedReceiptItem.discount = discount;
    selectedReceiptItem.isDiscPercent =
      discountType === "amount" ? false : true;
    selectedReceiptItem.type = receiptType;

    calculateBill();
    // let updatelist = receiptItems;
    // for (let i = 0; i < updatelist.length; i++) {
    //   const element = updatelist[i];
    //   if (element.sku === sku) {
    //     element.quantity = quantity;
    //     element.netAmount = netAmount;
    //     element.discount = discount;
    //     element.discountType = discountType;
    //     element.receiptType = receiptType;
    //     let recptItemRef1 = firebase
    //       .database()
    //       .ref(ReceiptItemTable + "/" + element.id);
    //     recptItemRef1.set(element);
    //   }
    // }

    // setReceiptItems(updatelist);
    // let bill1 = 0;
    // let return1 = 0;
    // for (let i = 0; i < updatelist.length; i++) {
    //   const element = updatelist[i];
    //   if (element.receiptType === "Return") {
    //     bill1 = bill1 - Math.abs(element.netAmount);
    //     return1 = returnAmount + Math.abs(element.netAmount);
    //   } else {
    //     bill1 = bill1 + element.netAmount;
    //   }
    // }
    // setreturnAmount(return1);
    // setBill(bill1);
    // setFinalNetAmount(bill1);
    // setDiscount(0);
    // setReceiptType("Sale");
    // setNetAmount(0);
    // setDiscountType("amount");
    // setQuantity(1);
    setShowItemModal(false);
  };

  const hideMdal = () => {
    setShowItemModal(false);
  };

  function onChangeDate(date, dateString) {
    if (date === null) {
      setDate(moment().format());
    } else {
      setDate(date);
    }
  }

  function onChangeOverAllDiscountValue(value) {
    receiptData.BillDiscount = Number(value.target.value);
    calculateBill();
  }

  function onChangeOverAllDiscountType(type) {
    if (type.target.value === "amount") receiptData.IsDiscPercent = false;
    else receiptData.IsDiscPercent = true;

    calculateBill();
  }

  const onChangeOverAllDiscount = (type, value) => {
    // if (type === "discount") {
    //   setoverAllDiscount(value.target.value);
    //   if (overAlldiscountType === "amount") {
    //     let netAmount1 = bill - value.target.value;
    //     setFinalNetAmount(netAmount1);
    //   } else {
    //     let percent = value.target.value / 100;
    //     let netAmount2 = bill - bill * percent;
    //     setFinalNetAmount(netAmount2);
    //   }
    // } else {
    //   setoverAlldiscountType(value.target.value);
    //   if (value.target.value === "amount") {
    //     let netAmount1 = bill - overAllDiscount;
    //     setFinalNetAmount(netAmount1);
    //   } else {
    //     let percent = overAllDiscount / 100;
    //     let netAmount2 = bill - bill * percent;
    //     setFinalNetAmount(netAmount2);
    //   }
    // }
  };

  function barcodeResult(value) {
    setBarcodeValue(value);
    setVisibleBarcode(false);
  }

  if (refresh) setRefresh(false);
  // if (!itemsLoaded) return <h2>Loading Receipt...</h2>;
  else
    return (
      <>
        <div className={classes.heading}>
          <div className={classes.paddedRight}>
            <LeftOutlined onClick={navigateBack}/>
          </div>
          Receipt Details
        </div>
        <Grid container className={classes.paddedBottom}>
          <Grid item xs={12} sm={6}>
            <Row justify="center" align="middle">
              <Form
                style={{ width: "95%", marginRight: 0 }}
                ref={formRef}
                {...formItemLayout}
                onFinish={() => handleSave()}
              >
                <Row justify="space-around" align="top" gutter={24}>
                  <Col span={4}>
                    <Form.Item>
                      <Input
                        placeholder="ID"
                        disabled={isReadOnly()}
                        value={refNum}
                        onChange={(value) => setRefNum(value.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Cust">
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        disabled={isReadOnly()}
                        onChange={(event, newValue) => {
                          handleSearch(newValue);
                        }}
                        value={parties && parties.length === 0 ? "" : partyName}
                        options={
                          parties && parties.map((option) => option.Name)
                        }
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              size="small"
                              placeholder={
                                isReadOnly() ? partyName : "Customers"
                              }
                              className={classes.customerInput}
                              onChange={(value) => handlePartyChanged(value)}
                              margin="normal"
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Date">
                      <DatePicker
                        disabled={isReadOnly()}
                        defaultValue={moment(moment().format(), dateFormat)}
                        value={moment(date, dateFormat)}
                        onChange={onChangeDate}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {receiptItems.length > 0 ? (
                  <>
                    <Grid container className={classes.itemsTableHead}>
                      <b> Items </b>
                      {!isReadOnly() && (
                        <Grid
                          className={classes.showItems}
                          onClick={() => setShowItems(!showItems)}
                        >
                          Show All Items
                          <RightOutlined />
                        </Grid>
                      )}
                    </Grid>
                    {receiptItems
                      .filter((r) => {
                        return r.isRemoved === undefined || !r.isRemoved;
                      })
                      .map((ritem, index) => {
                        return (
                          <Grid
                            key={index}
                            xs={12}
                            item
                            className={classes.list}
                          >
                            <Grid container justify={"space-between"}>
                              <div
                                onClick={() =>
                                  !isReadOnly() && openModal(ritem)
                                }
                                style={{
                                  cursor: !isReadOnly() ? "pointer" : "text",
                                }}
                                className={classes.itemName}
                              >
                                {(ritem.SKU && ritem.SKU + " - ") +
                                  ritem.itemStr}
                              </div>
                              <div>
                                <span>
                                  {ritem.netAmt
                                    ? ritem.netAmt
                                    : ritem.itemPrice}
                                </span>
                                {/* {!isReadOnly() && (
                                  <DeleteFilled
                                    onClick={() => onDeleteList(index)}
                                    className={classes.deleteIcon}
                                  />
                                )} */}
                              </div>
                            </Grid>
                            <Grid container justify={"space-between"}>
                              <Grid item className={classes.saleText}>
                                {ritem.type}
                              </Grid>
                              <Grid item>
                                {ritem.quantity + " x " + ritem.itemPrice}
                              </Grid>
                              {!isReadOnly() && (
                                <Grid item>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      {ritem.quantity > 1 ? (
                                        <MinusSquareOutlined
                                          onClick={() =>
                                            onDecrementQuant(index)
                                          }
                                        />
                                      ) : (
                                        <DeleteFilled
                                          onClick={() => onDeleteList(index)}
                                          className={classes.deleteIcon}
                                        />
                                      )}
                                    </Grid>

                                    <Grid item>{ritem.quantity}</Grid>

                                    <Grid item>
                                      <PlusSquareOutlined
                                        onClick={() => onIncrementQuant(index)}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        );
                      })}
                  </>
                ) : (
                  <Empty description="No receipt items" />
                )}
                <Hidden smUp implementation="css">
                  {showItems && !isReadOnly() && (
                    <Grid item xs={12} sm={6}>
                      <ItemsListView
                        onSelectItem={onSelectItem}
                        barcodevalue={barcodevalue}
                        items={ItemList}
                      />
                    </Grid>
                  )}
                </Hidden>
                {receiptItems && receiptItems.length > 0 && (
                  <Card className={classes.discountarea}>
                    {receiptData.Ret > 0 && (
                      <Form.Item label={"Return"}>{receiptData.Ret}</Form.Item>
                    )}
                    <Form.Item label={"Bill"}>{receiptData.Sale}</Form.Item>

                    <Form.Item label={"Discount"}>
                      <Input
                        placeholder="Discount"
                        disabled={isReadOnly()}
                        id="discount"
                        value={receiptData.BillDiscount}
                        onChange={(value) =>
                          onChangeOverAllDiscountValue(value)
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <Radio.Group
                        disabled={isReadOnly()}
                        value={receiptData.IsDiscPercent ? "percent" : "amount"}
                        onChange={(value) => onChangeOverAllDiscountType(value)}
                      >
                        <Radio value={"amount"}>Amount</Radio>
                        <Radio value={"percent"}>%</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label={"Net"}>{receiptData.Net}</Form.Item>
                    {Mode === 5 && (
                      <Form.Item label={"Paid Amount"}>
                        {receiptData.PaidAmount}
                      </Form.Item>
                    )}
                  </Card>
                )}

                {receiptItems && receiptItems.length > 0 && (
                  <Form.Item label="Remarks">
                    <Input
                      placeholder="Remarks"
                      disabled={isReadOnly()}
                      value={receiptData.Remarks}
                      onChange={(value) =>
                        (receiptData.Remarks = String(value))
                      }
                    />
                  </Form.Item>
                )}
              </Form>
            </Row>
          </Grid>
          <CheckoutModal
            visible={visibleCheckoutModal}
            confirmLoading={confirmLoadingCheckout}
            returnAmount={receiptData.Ret}
            overAllDiscount={receiptData.BillDiscount}
            onCancel={() => setVisibleCheckoutModal(false)}
            handleSubmit={handleCheckout}
            bill={receiptData.Sale}
            finalNetAmount={receiptData.Net}
          />
          <Grid item xs={12} sm={6}>
            <Grid className={classes.paddedBottom}>
              {!isReadOnly() && receiptItems.length > 0 && (
                <Button
                  type={"primary"}
                  onClick={() => setVisibleCheckoutModal(true)}
                  className={classes.checkout}
                >
                  Checkout
                </Button>
              )}
              {receiptData.Status === 5 && (
                <Button
                  type="primary"
                  onClick={() => setVisiblePayInstallmentModal(true)}
                  className={classes.checkout}
                >
                  Pay Installment
                </Button>
              )}
              {!isReadOnly() && receiptItems.length > 0 && (
                <Button type="primary" htmlType="submit" onClick={handleSave}>
                  Save
                </Button>
              )}
            </Grid>
            {showItems && (
              <Hidden xsDown implementation="css">
                <ItemsListView
                  onSelectItem={onSelectItem}
                  barcodevalue={barcodevalue}
                  items={ItemList}
                />
              </Hidden>
            )}
          </Grid>
          {/* unmount modal so that usestate works as expected */}
          {showItemModal && selectedReceiptItem && (
            <UpdateItemsForm
              data={selectedReceiptItem}
              onUpdate={onUpdateReceiptItem}
              onCancel={() => hideMdal()}
              visible={showItemModal}
              confirmLoading={loading}
            />
          )}
        </Grid>
        <Modal
          visible={visiblePayInstallmentModal}
          onCancel={() => setVisiblePayInstallmentModal(false)}
          onOk={handleInstallment}
          title="Pay Installment"
          // footer={null}
          width={"50%"}
        >
          <Form>
            <Form.Item label={"Remaining"}>
              {receiptData.Net - receiptData.PaidAmount}
            </Form.Item>
            <Form.Item label={"Installment Amount"}>
              <Input
                placeholder="Installment Amount"
                value={installmentAmount}
                onChange={(value) =>
                  setInstallmentAmount(Number(value.target.value))
                }
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
}
