import React, { createContext, useReducer } from "react";
import useLogger from "./useLogger";

const initialState = {
  posts: [],
  error: null,
};

export const LogContext = createContext(null);

const Logger = ({ children }) => {
  // const [state, dispatch] = useReducer(Reducer, initialState);
  const [logger, logSessionEnd] = useLogger();

  return (
    <LogContext.Provider value={[logger, logSessionEnd]}>{children}</LogContext.Provider>
  );
};

export default Logger;
