import dayjs from "dayjs";
import { ItemSaleMonthlyLookupDto } from "dto/ItemReportDto";
import firebase from "firebase";
import { ItemSaleMonthlyLookupModel, itemSaleLookupDtoToModel, itemSaleLookupModelToDto } from "model/ItemSaleMonthlyLookupModel";
import ItemSaleModel, { itemSaleModelToDto } from "model/report/ItemSaleModel";
import { ReceiptItemModel } from "model/ReceiptItemModel";
import { ReceiptModel } from "model/ReceiptModel";
import { ItemSaleMonthlyLookupTable, ItemSaleTable } from "utils/Constants";
import { log_db, reports_db } from "utils/Firebase";
import { dateFormatMMM_YY } from "utils/Globals";

export function saveItemSaleReportRepo(
  receiptData: ReceiptModel,
  receiptItems: Array<ReceiptItemModel>
) {
  const itemLookupRef = firebase
    .database(reports_db)
    .ref(ItemSaleMonthlyLookupTable());
  //itemLookupRef.
  const mmyy = dayjs().format(dateFormatMMM_YY);
  let totQuantity = 0;
  let totAmount = 0;

  // debugger
  // firebase.database(reports_db).ref('test/38/').push('sdfs')
  // .then((data) => {
  //    // success
  // })
  // .catch((err) => {
  //   debugger
  //   console.log("failed to post...", err)
  //    // failed
  // });

  // return
  receiptItems.forEach((rItem) => {
    const im: ItemSaleModel = new ItemSaleModel();
    im.amount = rItem.netAmt;
    im.date = receiptData.DateNum;
    im.itemId = rItem.itemId;
    im.quantity = rItem.quantity;
    im.receiptId = receiptData.Id;
    im.receiptItemId = rItem.Id;

    totQuantity += im.quantity;
    totAmount += im.amount;

    saveItemSaleReport(im, mmyy, rItem.Id);

    itemLookupRef
      .child(mmyy)
      .child(rItem.itemId)
      .once("value", (snapshot) => {
        let m: ItemSaleMonthlyLookupModel = itemSaleLookupDtoToModel(snapshot.val());
        if (m === null) m = new ItemSaleMonthlyLookupModel();
        m.quantity += im.quantity;
        m.value += im.amount;

        const dto = JSON.parse(JSON.stringify(itemSaleLookupModelToDto(m)));
        itemLookupRef.child(mmyy)
        .child(rItem.itemId).update(dto, (err) => {
          // if (err) {
          //   if (error) error(err);
          // }
        });
      });
  });
}
function saveItemSaleReport(im: ItemSaleModel, mmyy: string, rItemId: string) {
  const itemSaleRef = firebase.database(reports_db).ref(ItemSaleTable());
  let dto = itemSaleModelToDto(im);
  let js = JSON.parse(JSON.stringify(dto));
  itemSaleRef.child(mmyy).child(rItemId).set(js);
}
