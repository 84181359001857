import ServiceBase from "services/ServiceBase";
import { ReceiptItemTable } from "utils/Constants";
import { LoadAllItems, SaveItemRepo } from "repo/ItemRepo";
import ItemModel from "model/ItemModel";
import PartyModel from "model/PartyModel";
import { SavePartyRepo } from "repo/PartyRepo";

export class LoadPartiesService extends ServiceBase {
  action() {}
}

export class SavePartyService extends ServiceBase {
  action(party: PartyModel) {
    SavePartyRepo(party, (result) => this.callback(result));
  }
}
