import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Form, Input, InputNumber, Col, Button, Row, Card } from "antd";
import { SaveItemService } from "services/ItemService";
import DatePicker from "components/utility/DatePicker";
import ItemModel from "model/ItemModel";
import { useSender } from "utils/useFetcher";
import "./item.css";
import dayjs from "dayjs";
import useStyles from "components/style";

const { RangePicker } = DatePicker;

export default function AddEditItem(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [item, setItem] = useState<ItemModel>(
    state ? (state as ItemModel) : new ItemModel()
  );
  const classes = useStyles();

  const formRef = useRef(null);
  const [saver, saveItemService] = useSender(SaveItemService);

  const panelLayout = {
    xs: 24,
    sm: 24,
    md: 16,
    lg: 12,
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 18 },
    },
  };

  const buttonItemLayout = {
    wrapperCol: { offset: 8 },
  };

  const handleFinish = (values) => {
    console.log(values);

    //let item = new ItemModel();
    item.Date = dayjs().unix();
    item.Name = values.name;
    item.Sku = values.sku;
    item.Desc = values.desc;

    if (values.mdate[0]) {
      item.Stock.MfgDate = values.mdate[0].unix();
      item.Stock.ExpDate = values.mdate[1].unix();
    }

    item.PurchasePrice = values.pprice;
    item.SalePrice = values.sprice;

    if (values.quantity) item.Stock.ItemCount = values.quantity;
    if (values.batch) item.Stock.Batch = values.batch;
    if (values.location) item.Stock.Location = values.location;
    //  item.brandId = values.brand;
    //  item.categotyId = values.category;
    item.isActive = 1;
    saveItemService(null, item);

    navigate(-1); // go to previous page
  };

  function getMfgDate() {
    return item.Stock?.MfgDate && dayjs.unix(item.Stock.MfgDate);
  }

  function getExpDate() {
    return item.Stock?.ExpDate && dayjs.unix(item.Stock.ExpDate);
  }

  console.log("sku = ", item.Sku);
  return (
    <>
      <Button
        className={classes.margin}
        type="primary"
        onClick={() => navigate(-1)}
      >
        Back
      </Button>

      <Row justify="center" className="item" style={{ minHeight: "100vh" }}>
        <Col {...panelLayout}>
          <Card title="Item Details" className="card">
            <Form
              ref={formRef}
              onFinish={handleFinish}
              {...formItemLayout}
              initialValues={{
                sku: item.Sku,
                name: item.Name,
                desc: item.Desc,
                //    category: item.categotyId,
                //    brand: item.brandId,
                pprice: item.PurchasePrice,
                sprice: item.SalePrice,
                quantity: item.Stock?.ItemCount,
                batch: item.Stock?.Batch,
                mdate: [getMfgDate(), getExpDate()],
                location: item.Stock?.Location,
              }}
            >
              <Form.Item rules={[{ required: false }]} name="sku" label="SKU">
                <Input placeholder="SKU" id="sku" />
              </Form.Item>

              <Form.Item rules={[{ required: true }]} name="name" label="Name">
                <Input placeholder="Item name" id="name" />
              </Form.Item>

              <Form.Item
                rules={[{ required: false }]}
                name="desc"
                label="Description"
              >
                <Input placeholder="Description" id="desc" />
              </Form.Item>

              {/* <Form.Item
                rules={[{ required: false }]}
                name="category"
                label="Category"
              >
                <Input
                  placeholder="Category"
                  id="category"
                />
              </Form.Item>

              <Form.Item
                rules={[{ required: false }]}
                name="brand"
                label="Brand"
              >
                <Input
                  placeholder="Brand"
                  id="brand"
                />
              </Form.Item> */}

              <Form.Item
                rules={[{ required: false }]}
                name="pprice"
                label="Purchase Price"
              >
                <InputNumber
                  style={{ width: "50%" }}
                  placeholder="Purchase Price"
                  id="pprice"
                />
              </Form.Item>

              <Form.Item
                rules={[{ required: false }]}
                name="sprice"
                label="Sale Price"
              >
                <InputNumber
                  style={{ width: "50%" }}
                  placeholder="Sale Price"
                  id="sprice"
                />
              </Form.Item>

              <Card title="Stock" className="stockCard">
                <Form.Item
                  rules={[{ required: false }]}
                  name="quantity"
                  label="Quantity"
                >
                  <InputNumber
                    style={{ width: "50%" }}
                    placeholder="Quantity"
                    id="quantity"
                  />
                </Form.Item>

                <Form.Item
                  rules={[{ required: false }]}
                  name="batch"
                  label="Batch"
                >
                  <Input
                    placeholder="Batch"
                    id="batch"
                    value={item.Stock?.Batch}
                  />
                </Form.Item>

                <Form.Item
                  rules={[{ required: false }]}
                  name="mdate"
                  label="Mfg Date"
                >
                  <RangePicker
                    placeholder={["Mfg Date", "Exp Date"]}
                    id="mdate"
                    // defaultValue={[
                    //   dayjs(item.Stock.MfgDate),
                    //   dayjs(item.Stock.ExpDate),
                    // ]}
                  />
                </Form.Item>

                {/* <Form.Item
                rules={[{ required: false }]}
                name="edate"
                label="Expiry Date"
              >
                <Input placeholder="Exp Date" id="edate" />
              </Form.Item> */}

                <Form.Item
                  rules={[{ required: false }]}
                  name="location"
                  label="Location"
                >
                  <Input placeholder="Location" id="loc" />
                </Form.Item>
              </Card>

              <Form.Item {...buttonItemLayout}>
                <Button
                  className={classes.margin}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}
