import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import { HandleLogout } from "./Globals";
import { useAppDispatch } from "redux/hooks";

const useFetcher = function<T>(props, initialVal = null) {
  const [loaded, setLoaded] = useState<T>(initialVal);
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  //  const [model, setModel] = useState(null);

  function loadData(callback?, payload?) {
    const val = Cookies.get("valid")
    // cookies values are string, hence == is fine
    if (val === "true") {
      let model = new props(navigate, callback, setLoaded, dispatch);
      model.action(payload);
    } else {
      HandleLogout(navigate);
      return;
    }
    //setModel(m)
  }

  //  console.log('loaded', loaded)
  return [loaded, setLoaded, loadData] as const;
};

export default useFetcher;

// a bit different from useFetcher, it can accept multi params as data to save
export const useSender = (props, initialVal = null) => {
  const [result, setResult] = useState(initialVal);
  const navigate = useNavigate();
  //  const [model, setModel] = useState(null);

  function saveData(callback, ...payload) {
    if (Cookies.get("valid")) {
      let model = new props(navigate, callback, setResult);
      model.action(...payload);
    } else {
      HandleLogout(navigate);
      return;
    }

    //setModel(m)
  }

  //  console.log('loaded', loaded)
  return [result, saveData];
};
