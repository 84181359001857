import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Table, Button, Spin } from "antd";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { dateFormat, dateStringFormat } from "utils/Globals";
import "./receipt.css";

export default function ReceiptsListView(props) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const columns: any = [
    {
      title: "Ref #",
      dataIndex: "RefNum",
      key: "RefNum",
      sorter: {
        compare: (a, b) => a.RefNum - b.RefNum,
      },
    },
    {
      title: "Date",
      //  dataIndex: "Date",
      key: "date",
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        if (!record.DateNum) return "";
        return moment(record.DateNum).format(dateStringFormat);
      },
      sorter: {
        compare: (a, b) => {
          if (a.DateNum && b.DateNum) return a.DateNum - b.DateNum;
          if (!a.DateNum && !b.DateNum) return 0;
          if (!a.DateNum) return -1;
          if (!b.DateNum) return 1;
        },
      },
    },
    {
      title: "Bill Amount",
      dataIndex: "Sale",
      key: "sale",
      sorter: {
        compare: (a, b) => a.Sale - b.Sale,
      },
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      align: 'center',
      render: (text, record, index) => {
        return {
          props: {
            style: { textAlign: "center" },
          },
          children:
            text === 1 ? (
              <div className='green'>On Hold</div>
            ) : text === 5 ? (
              <div className="red">Credit</div>
            ) : (
              "Paid"
            ),
        };
      },
    },
  ];

  console.log("loading ", props.loading);

  return (
    <>
      <Table
        dataSource={props.receipts}
        sortDirections={["ascend", "descend", "ascend"]}
        columns={columns}
        pagination={false}
        loading={{ indicator: <Spin />, spinning: props.loading }}
        onRow={(record) => {
            return {
              onClick: (event) => {
                navigate("/app/addreceipt", { state: record });
            },
            };
          }}
      />
    </>
  );
}
