import { GetCompanyId } from "utils/Globals";

export const ItemTable =  () => 'Item/' + GetCompanyId()
export const ReceiptTable = () => 'Receipt/' + GetCompanyId()
export const PartyTable = () => 'Party/' + GetCompanyId()
export const ReceiptNotificationTable = () => 'ReceiptNotification/' + GetCompanyId()
export const ReceiptPaymentTable = () => 'ReceiptPayment/' + GetCompanyId()
export const ReceiptItemTable = () => 'ReceiptItem/' + GetCompanyId()

export const ItemSaleMonthlyLookupTable = () => 'ItemSaleMonthlyLookup/' + GetCompanyId()
export const ItemSaleTable = () => 'ItemSale/' + GetCompanyId()

export const CompanyTable = () => 'Company/' + GetCompanyId()

export const LogUserTable = () => "Logs/User/" + GetCompanyId()
export const LogSessionTable = () => "Logs/Session/" + GetCompanyId()
export const LogSessionEventTable = () => "Logs/SessionEvent/" + GetCompanyId()
