import { notification } from 'antd'

export default abstract class ServiceBase {
  history: any
  callback: any
  setdataLoaded: any
  dispatch: any

  constructor(hist?, callback?, setdataLoaded?, dispatch?) {
    if(arguments.length === 4) {
      this.history = hist
      this.callback = callback
      this.setdataLoaded = setdataLoaded
      this.dispatch = dispatch
    }
  }

  action(...params: any[]) {}

  cleanup() {}

  sendCallback(params) {
    if(this.callback)
      this.callback(params)
  }

  handleError(error) {
    console.log(error);
    notification.error({
        message: error.code,
        description: error.message
      });

    if (error.code.includes('PERMISSION_DENIED'))
        this.history("login");
  }
}
