import React from "react";
import App from "App";
import { BrowserRouter, Route, Navigate } from "react-router-dom";
import { Routes } from 'react-router'
import { GetIsLoggedIn } from "utils/Globals";
import Dashboard from "components/Dashboard";
import Items from "components/item/Items";
import PrivateRoute from "route/PrivateRoute";
import LoginPage from "components/Login";
import { LayoutAuthenticated } from "route/LayoutAuthenticated";
import LayoutAnonymous from "route/LayoutAnonymous";
import AddEditItem from 'components/item/AddEditItem'
import Receipts from 'components/receipt/Receipts'
import AddEditReceipt from 'components/receipt/AddEditReceipt'
import Authenticate from 'components/Authenticate'
import Logout from 'components/Logout'

// export default function AppRoutes() {
//   const privateRoutes = [
//     {
//       key: "home",
//       path: "/",
//       element: Dashboard,
//       exact: true,
//     },
//     {
//       key: "items",
//       path: "/items",
//       component: Items,
//       exact: true,
//     },
//     {
//       key: "additem",
//       path: "/additem",
//       component: AddEditItem,
//       exact: true,
//     },
//     {
//       key: "receipts",
//       path: "/receipts",
//       component: Receipts,
//       exact: true,
//     },
//     {
//       key: "addreceipt",
//       path: "/addreceipt",
//       component: AddEditReceipt,
//       exact: true,
//     },
//   ];
// }

const routes = [
  {
    path: '/app',
    element: <LayoutAuthenticated />,
    children: [
      { path: '/', element: <Dashboard   /> },
      { path: '/items', element: <Items /> },
      { path: '/additem', element: <AddEditItem /> },
      { path: '/receipts', element: <Receipts /> },
      { path: '/addreceipt', element: <AddEditReceipt /> },
      { path: '/logout', element: <Logout /> },
      { path: '*', element: <Navigate to="/aaa" /> }
    ]
  },
  {
    path: '/',
    element: <LayoutAnonymous />,
    children: [
      { path: 'login', element: <LoginPage /> },
      // { path: 'register', element: <RegisterView /> },
      // { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app" /> },
    ]
  }
];

export default routes;
//   return (
//     <BrowserRouter>
//     {/* <Route path='/' element={<Dashboard />} /> */}
//       <Routes>
//         <Route path={"/login" | "/signup" | "/forgot-password"}>
//           <LayoutAnonymous>
//             <Route  path="/login" element={<LoginPage />} />
//           </LayoutAnonymous>
//         </Route>

//         <Route path="/">
//           <LayoutAuthenticated>
//             <Routes>
//               {privateRoutes.map((privateRouteProps) => (
//                 <PrivateRoute
//                   {...privateRouteProps}
//                   authenticated={GetIsLoggedIn()}
//                 />
//               ))}
//             </Routes>
//           </LayoutAuthenticated>
//         </Route>
//       </Routes>
//     </BrowserRouter>
//   );
// }
