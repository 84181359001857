import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: 10,
    },
    padded: {
      padded: 10,
    },
    heading: {
      margin: 10,
      fontSize: 24,
      fontWeight: 500
    }
  }))

  export default useStyles;
  