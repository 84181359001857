import ServiceBase from "services/ServiceBase";
import { ReceiptItemTable } from "utils/Constants";
import { LoadAllItems, SaveItemRepo } from "repo/ItemRepo";
import ItemModel from "model/ItemModel";

export class AllItemsLoader extends ServiceBase {
  action() {
    LoadAllItems(
      (items: ItemModel[]) => {
        this.setdataLoaded(items);
        this.sendCallback(items);
      },
      (error) => this.handleError(error)
    );
  }
}

export class SaveItemService extends ServiceBase {
  action(item: ItemModel) {
    SaveItemRepo(item, (result) => this.sendCallback(result));
  }
}
