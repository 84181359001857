import { useCallback } from 'react'
import ServiceBase from "services/ServiceBase";
import { ReceiptTable } from "utils/Constants";
import {
  LoadReceipts,
  SaveReceipt,
  UpdateReceiptPaidAmountRepo,
  UpdateReceiptTotalPurchPrice,
} from "repo/ReceiptRepo";
import { ReceiptDto, ReceiptStatus } from "dto/ReceiptDto";
import {
  CreateReceiptPaymentModel,
  SaveReceiptPayment,
} from "repo/ReceiptPaymentRepo";
import {
  CreateReceiptNotification,
  SaveReceiptNotification,
  UpdateReceiptNotification,
} from "repo/ReceiptNotificationRepo";
import { SaveReceiptItem } from "repo/ReceiptItemRepo";
import { ReceiptModel } from "model/ReceiptModel";
import ReceiptPaymentDto from "dto/ReceiptPaymentDto";
import { ReceiptNotificationModel } from "model/ReceiptNotificationModel";
import ReceiptPaymentModel from "model/ReceiptPaymentModel";
import { ReportService } from "./ReportService";
import { ReceiptItemModel, ReceiptItemModelExt } from "model/ReceiptItemModel";
import { setReceiptsList } from "redux/reducers/rootReducer";

export class AllReceiptsLoader extends ServiceBase {
  action() {
    LoadReceipts(
      (receipts) => {
        //   this.setdataLoaded(receipts); // This is also an alternative approach to set data in addition to calling callback
        this.dispatch(setReceiptsList(receipts)); // set thru redux
        // this.callback(receipts);
      },
      (error) => this.handleError(error)
    );
  }
}

// tslint:disable-next-line: max-classes-per-file
export class SaveReceiptService extends ServiceBase {
  action(receiptData: ReceiptModel, receiptItems: ReceiptItemModelExt[]) {
    SaveReceipt(receiptData); // save receipt

    // set recieptid in receiptitems
    receiptItems.forEach((ri) => {
      //  if (ri.isNew) {
      ri.receiptID = receiptData.Id;
      // purchPrice += ri.quantity * ri.purcPrice
      SaveReceiptItem(ri);
      //  }
    });

    // receiptData.TotalPurchPrice = purchPrice
    UpdateReceiptTotalPurchPrice(receiptData);
  }
}

// tslint:disable-next-line: max-classes-per-file
export class CheckoutReceiptService extends ServiceBase {
  action(
    isCredit: boolean,
    amountPaid: number,
    receiptData: ReceiptModel,
    receiptItems: ReceiptItemModelExt[]
  ) {
    if (amountPaid > receiptData.Net) amountPaid = receiptData.Net; // if paid more, rest shld be given as change, not saved as paid amt
    receiptData.PaidAmount = amountPaid;

    if (isCredit) {
      // save in notificaiton
      receiptData.Status = ReceiptStatus.Installment;
    } else {
      receiptData.Status = ReceiptStatus.Done;
      if (amountPaid < receiptData.Net) receiptData.Net = amountPaid;
    }

    // save rectp and ritems
    receiptData.PaidAmount = amountPaid;
    const saveService = new SaveReceiptService(null, null, null);
    saveService.action(receiptData, receiptItems);

    if (isCredit) {
      const recNotification: ReceiptNotificationModel = CreateReceiptNotification();
      recNotification.PartyId = receiptData.PartyId;
      recNotification.DueAmount = receiptData.Net - receiptData.PaidAmount;
      SaveReceiptNotification(receiptData.Id, recNotification);
    }

    // save recp payment
    const rePayment = CreateReceiptPaymentModel();
    rePayment.ReceiptId = receiptData.Id;
    rePayment.Amount = amountPaid;
    SaveReceiptPayment(rePayment);

    const reportService: ReportService = new ReportService();
    reportService.saveItemMonthlyReport(receiptData, receiptItems);
  }
}

// All business logic of receiptpayment shld reside here
// tslint:disable-next-line: max-classes-per-file
export class UpdateReceiptPaidAmount extends ServiceBase {
  action(receiptDTO: ReceiptModel, payment: number) {
    let errorHandled = false;

    // promise to catch all errors and display one error
    new Promise((resolve, reject) => {
      receiptDTO.PaidAmount += payment; // business rule
      // we could write generic update for receipt, which updates all fields
      UpdateReceiptPaidAmountRepo(receiptDTO, (error) =>
        // this.handleError(error)
        reject(error)
      );

      // add receiptpayment in db
      const receiptPayment: ReceiptPaymentModel = CreateReceiptPaymentModel();
      receiptPayment.ReceiptId = receiptDTO.Id;
      receiptPayment.Amount = payment;
      SaveReceiptPayment(receiptPayment, (error) => reject(error));

      // add receiptnotification in db
      UpdateReceiptNotification(receiptDTO.Id, payment, (error) =>
        reject(error)
      );
    }).catch(() => {
      if (!errorHandled) this.handleError("Pay installment failed");
      errorHandled = true;
    });
  }
}
