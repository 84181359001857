import React from "react";
import { Layout, Menu, Breadcrumb } from "antd";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import "index.css";
import { Redirect, useNavigate } from "react-router";
import { Grid, makeStyles, TextField, Hidden } from "@material-ui/core";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const useStyles = makeStyles((theme) => ({
  display: {
   // display: 'none'
  },
}))

export default function UserSideBar() {
  const navigate = useNavigate();
  const classes = useStyles();

  const onItemsClick = (e) => {
    navigate("items");
  };

  const onReceiptsClick = (e) => {
    navigate("receipts");
  };

  return (
    <Layout>
      <Sider>
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          style={{ height: "100%", borderRight: 0 }}
        >
          <SubMenu key="sub1" icon={<UserOutlined />} title="Actions">
            <Menu.Item key="1" onClick={onItemsClick}>
              Items
            </Menu.Item>
            <Menu.Item key="2" onClick={onReceiptsClick}>
              Receipts
            </Menu.Item>
            <Menu.Item key="3">option3</Menu.Item>
            <Menu.Item key="4">option4</Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" icon={<LaptopOutlined />} title="subnav 2">
            <Menu.Item key="5">option5</Menu.Item>
            <Menu.Item key="6">option6</Menu.Item>
            <Menu.Item key="7">option7</Menu.Item>
            <Menu.Item key="8">option8</Menu.Item>
          </SubMenu>
          <SubMenu key="sub3" icon={<NotificationOutlined />} title="subnav 3">
            <Menu.Item key="9">option9</Menu.Item>
            <Menu.Item key="10">option10</Menu.Item>
            <Menu.Item key="11">option11</Menu.Item>
            <Menu.Item key="12">option12</Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
    </Layout>
  );
}
