/* eslint-disable no-trailing-spaces */
//import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
//import GlobalStyles from 'src/components/GlobalStyles';
//import theme from 'src/theme';
import "./App.css";
import routes from 'route/Routes';

const App = () => {
  const routing = useRoutes(routes);

  return (       
    <div>
      {routing}
    </div>
  );
};

export default App;
