import React, { useContext, useEffect, useState } from "react";
import { ItemList } from "repo/ItemRepo";
import { AllItemsLoader } from "services/ItemService";
import ItemsListView from "./ItemsListView";
import { Button, Space } from "antd";
import { useNavigate } from "react-router";
import useFetcher from "utils/useFetcher";
import { ItemTable } from "utils/Constants";
import firebase from "utils/Firebase";
import { SettingsRemoteSharp } from "@material-ui/icons";
import useStyles from "components/style";
import ItemModel from "model/ItemModel";
import useLogger from "utils/useLogger";
import { LOG_ADD_ITEM } from "utils/LogConstants";
import { LogContext } from "utils/GlobalLogger";

export default function Items(props) {
  const navigate = useNavigate();
  const [items, setItems, loadAllItems] = useFetcher<ItemModel[]>(AllItemsLoader);
//  const [logger] = useLogger()
  const [logger] = useContext(LogContext)
  const [loaded, setLoaded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    console.log("items count = ", ItemList.length);

    if (ItemList.length > 0) {
      setItems(ItemList);
      setLoaded(true);
      return;
    }

    loadAllItems((res) => {
      setItems(res);
      setLoaded(true);
    });
  }, []);

  const handleAddItem = (e) => {
    logger(LOG_ADD_ITEM)
    navigate("/app/additem");
  };

  //if (!loaded) return <h2>Loading items...</h2>;
  //else
  return (
    <>
      <div className={classes.heading}>All Items</div>

      <Button type="primary" className={classes.margin} onClick={handleAddItem}>
        Add Item
      </Button>
      <ItemsListView items={items} loaded={loaded} />
    </>
  );
}
